export class Hamburger {
    constructor(button, expandedContainer, body) {
        this.button = button;
        this.expandedContainer = expandedContainer;
        this.body = body;
        this.addEventListeners();
    }

    expand() {
        this.button.classList.toggle('change');
        this.expandedContainer.classList.toggle('expanded');
        this.body.classList.toggle('fixed-body-hamburger');
    }

    addEventListeners() {
        if (this.button)
            this.button.addEventListener('click', this.expand.bind(this));
    }
}
