// eslint-disable-next-line no-unused-vars
import { Question } from './question';

export class Category {
    /**
     * @param {Number} id
     * @param {String} name
     * @param {Question[]} questions
     */
    constructor(id, name, questions) {
        this.id = id;
        this.name = name;
        this.questions = questions;
    }
}
