// eslint-disable-next-line no-unused-vars
import { Http } from './http';
import { Category } from './category';
import { Question } from './question';

export const CATEGORY_NAME_TERMS = 'terms';
export const CATEGORY_NAME_PRIVACY = 'privacy_policy';
export const CATEGORY_NAME_ESIGN = 'esign-document-delivery';
export const CATEGORY_NAME_ABOUT = 'about';

export class Categories {
    /**
     * @param {Http} http
     */
    constructor(http) {
        this._http = http;

        this._error = false;
        this._loaded = false;
        this.categories = [];
    }

    /**
     * @returns {Promise<Category[]>}
     */
    loadData() {
        return this._http
            .get('/v1/faq/category')
            .then(this._parseCategories.bind(this))
            .then(this._handleCategories.bind(this));
    }

    /**
     * @param {String} name
     * @returns {Category}
     */
    getByName(name) {
        return this.categories.find(c => c.name === name);
    }
    /**
     * @param {array} names
     * @returns {Category[]}
     */
    getAllBut(names) {
        return this.categories.filter(c => !names.includes(c.name));
    }

    /**
     * @returns {Boolean}
     */
    loadedSuccessfully() {
        return this._loaded && !this._error;
    }

    /**
     * @returns {Boolean}
     */
    loaded() {
        return this._loaded;
    }

    _parseCategories(cats) {
        if (cats.length) {
            return cats.map(this._parseCategory.bind(this));
        }

        return [];
    }

    _parseCategory(cat) {
        let questions = [];

        if (cat.questions && cat.questions.length) {
            questions = cat.questions.map(this._parseQuestion);
        }

        return new Category(cat.id, cat.name, questions);
    }

    _parseQuestion(q) {
        return new Question(q.id, q.question, q.answer);
    }

    _handleCategories(categories) {
        this.categories = categories;
        this._loaded = true;

        return categories;
    }

    _handleError() {
        this._error = true;
        this._loaded = true;
    }
}
