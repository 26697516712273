export class Link {
    /**
     * @param {String} url
     * @param {String} text
     */
    constructor(url, text) {
        this.url = url;
        this.text = text;
    }
}
