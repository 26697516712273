// eslint-disable-next-line no-unused-vars
import { Form } from '../form';
import { FormValidation } from '../form-validation';
import { FormLabel } from '../form-label';
import { Dropdown, DROPDOWN_CONTROL_CLASS } from '../dropdown';
// eslint-disable-next-line no-unused-vars
import { Http } from '../../data/http';
import Inputmask from 'inputmask';

export const LOADING_CLASS = 'loading';

export class FormType {
    /**
     * @param {Form} form
     * @param {Document} doc
     * @param {Http} http
     */
    constructor(form, doc, http) {
        this.form = form;
        this.doc = doc;
        this.http = http;

        this._labels();
        this._dropdowns();
        this._validation();
        this._telFields();
        this._triggerRecaptcha();
    }

    _labels() {
        this.form.getFormControls().forEach(fc => new FormLabel(fc));
    }

    _validation() {
        new FormValidation(this.form);
    }

    _dropdowns() {
        this.form
            .getFormControls()
            .filter(ctrl => ctrl.classList.contains(DROPDOWN_CONTROL_CLASS))
            .forEach(ctrl => new Dropdown(ctrl, this.doc));
    }

    _telFields() {
        let im = new Inputmask({
            mask: '999-999-9999',
            showMaskOnHover: false
        });

        this.form
            .getFields()
            .filter(ctrl => ctrl.type === 'tel')
            .forEach(ctrl => im.mask(ctrl));
    }

    _startLoading() {
        this.form.getSubmitButton().classList.add(LOADING_CLASS);
    }

    _stopLoading() {
        this.form.getSubmitButton().classList.remove(LOADING_CLASS);
    }

    _triggerRecaptcha() {
        window.recaptchaCallbacks[
            this.form.getId()
        ] = this._recaptchaComplete.bind(this);

        this.form.onSubmit(this._onSubmit.bind(this));
    }

    _onSubmit(e) {
        e.preventDefault();

        this._startLoading();

        window.grecaptcha.execute(window.recaptchaWidgetIds[this.form.getId()]);
    }

    _recaptchaComplete() {}
}
