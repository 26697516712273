let scrollY = 0;
// let prevScrollY = 0;
// let windowHeight = 0;
let ticking = false;

export class JumpToTop {
    /**
     * @param {Window} window
     * @param {HTMLDivElement} sideCtaElm
     */
    constructor(window, sideCtaElm) {
        this._window = window;
        // windowHeight = this._window.outerHeight;
        this._sideCtaElm = sideCtaElm;

        this.addEventListeners();
    }

    addEventListeners() {
        this._window.addEventListener('scroll', this._handleScroll.bind(this));
        this._sideCtaElm.addEventListener(
            'click',
            this._handleClick.bind(this)
        );
    }

    _handleScroll() {
        scrollY = this._window.pageYOffset;
        this._requestTick();
    }

    _handleClick() {
        scrollY = 0;
        this._window.scrollTo(0, 0);
        this._sideCtaElm.classList.remove('show');
    }

    _requestTick() {
        if (!ticking) {
            this._window.requestAnimationFrame(this._update.bind(this));
            ticking = true;
        }
    }

    _update() {
        if (scrollY > 250) {
            this._sideCtaElm.classList.add('show');
        } else {
            this._sideCtaElm.classList.remove('show');
        }
        // prevScrollY = scrollY;
        ticking = false;
    }
}
