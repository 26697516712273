const AUTO_SLIDE_CELL_DURATION = 15000;

export class AddActiveClass {
    constructor(collection, className, videoContainer) {
        this.collection = collection;
        this.className = className;
        this.videoContainer = videoContainer;
        this.currentIndex = 0;
        this.startCarousel();
        this.addEventListeners();
        if (videoContainer && (window.innerWidth > 800)) {
            this.setTimer();
        }
        if (!videoContainer && (window.innerWidth < 800)) {
            this.resetActiveCardOnMobile();
        }
    }

    removeReplayButton(collection) {
        for (let v = 0; v < collection.length; v++) {
            let image = collection[v].querySelector('img');
            image.classList.remove('show-replay');
        }
    }

    startCarousel() {
        if (this.collection[0].classList.contains('step1')) {
            this.makeActive(this.collection[0])
        }
    }

    setTimer() {
        this.timer = setInterval(this.rotateCell.bind(this), AUTO_SLIDE_CELL_DURATION);
    }

    stopTimer() {
        clearTimeout(this.timer);
    }

    rotateCell() {
        this.currentIndex++;
        if (this.currentIndex > this.collection.length - 1)
            this.currentIndex = 0;

        this.clearCurrentlyActive();
        this.collection[this.currentIndex].click();
        this.collection[this.currentIndex].classList.add('active-cell');
        this.videoContainer[0].children[this.currentIndex].classList.add('active-video');
    }

    clearCurrentlyActive() {
        let videos = this.videoContainer[0].children;

        for (let c = 0; c < this.collection.length; c++) {
            this.collection[c].classList.remove('active-cell');
        }

        for (let v = 0; v < videos.length; v++) {
            videos[v].classList.remove('active-video');
        }
    }

    playVideo(video) {
        video.pause();
        // video.currentTime = '0';
        video.play();
    }

    videoEnd(video, replayButton) {
        video.addEventListener('ended', function() {
            if (video.parentElement.classList.contains('active-video')) {
                replayButton.classList.add('show-replay');
            }
            replayButton.addEventListener('click', function() {
                video.pause();
                video.currentTime = '0';
                video.play();
            })
        })
    }

    resetIndex(selector) {
        let index = (parseInt(selector.classList[0].split('step')[1]) - 1)
        this.stopTimer();
        this.currentIndex = index;
        this.setTimer();
    }

    makeActive(selector) {
        for (var i = 0; i < this.collection.length; i++) {
            this.collection[i].classList.remove(this.className);
        }

        if (this.videoContainer == null) {
            this.determineBorderRadius(selector);
        }

        selector.classList.add(this.className);

        if (this.videoContainer) {
            let activeVideo = selector.classList[0];

            if (window.innerWidth > 800) {
                this.resetIndex(selector);
            }
            this.setVideoLogic(activeVideo);
        }
    }

    resetActiveCardOnMobile() {
        // default to the first card being active on mobile
        for (let i = 0; i < this.collection.length; i++) {
            this.collection[i].classList.remove('active-card');
        }
        this.collection[0].classList.add('active-card');
    }

    setVideoLogic(activeVideo) {
        let videos = this.videoContainer[0].children;

        for (var x = 0; x < videos.length; x++) {
            videos[x].classList.remove('active-video');

            if (videos[x].classList[0] == activeVideo) {
                this.removeReplayButton(videos);
                videos[x].classList.add('active-video');
                let replayButton = videos[x].querySelector('img');
                let video = videos[x].querySelector('video');
                this.playVideo(video);
                this.videoEnd(video, replayButton);
            }
        }
    }

    determineBorderRadius(selector) {
        // function assumes three cards
        for (let i = 0; i < this.collection.length; i++) {
            this.collection[i].classList.remove('adjusted-border-radius-right');
            this.collection[i].classList.remove('adjusted-border-radius-left');
        }

        let card = selector.classList[1];

        if (card == 'preferred-green') {
            this.collection[0].classList.add('adjusted-border-radius-right');
            this.collection[2].classList.add('adjusted-border-radius-left');
        }

        if (card == 'primary-blue') {
            this.collection[1].classList.add('adjusted-border-radius-left');
        }

        if (card == 'premier-green') {
            this.collection[1].classList.add('adjusted-border-radius-right');   
        }

    }

    addEventListeners() {
        for (let i = 0; i < this.collection.length; i++) {
            this.collection[i].addEventListener('click', event => {
                this.makeActive(event.currentTarget);
            });
        }
    }
}
