import { Form } from '../forms/form';
import { Help as HelpForm } from '../forms/types/help';
import { Outline } from './outline';
import { ScrollIntoView } from '../components/scroll-into-view';
import {
    CATEGORY_NAME_TERMS,
    CATEGORY_NAME_PRIVACY,
    CATEGORY_NAME_ESIGN,
    CATEGORY_NAME_ABOUT
} from '../data/categories';
import { OutlineDropdown } from '../components/outline-dropdown';
import { FAQ_SECTION_CLICK_EVENT } from '../analytics/google';

export class Help extends Outline {
    init() {
        super.init();

        this._form();
        this._toggleDropdown();
        this._scrollIntoView();
    }

    _setPageTitle() {
        this._document.title = 'optOn | Help';
    }

    _form() {
        let helpForm = document.getElementById('help-form');

        if (helpForm) new HelpForm(new Form(helpForm), document, this._http);
    }

    _handleSuccess() {
        this._hideLoader();
        let categories = this._categories.getAllBut([
            CATEGORY_NAME_TERMS,
            CATEGORY_NAME_PRIVACY,
            CATEGORY_NAME_ESIGN,
            CATEGORY_NAME_ABOUT
        ]);

        if (categories) {
            this._createMobileLayout(categories);
            this._printTerms(categories);
            this._printQuestions(categories[0]);
        } else {
            this._handleError();
        }
    }

    _scrollIntoView() {
        let contactUsElement = this._document.querySelector('.contact-us-cta');
        let contactUsTarget = this._document.querySelector(
            '#help-contact-us-form'
        );

        let faqElement = this._document.querySelector('.faq-cta');
        let faqElementMobile = this._document.querySelector('.faq-cta-mobile');
        let faqTarget = this._document.querySelector('#faqs-section');

        new ScrollIntoView(contactUsElement, contactUsTarget);
        new ScrollIntoView(faqElement, faqTarget);
        new ScrollIntoView(faqElementMobile, faqTarget);
    }

    _toggleDropdown(event) {
        if (event) {
            event.srcElement.closest('div').classList.toggle('active-question');
        }
    }

    _printTerms(categories) {
        categories.forEach(this._printLink.bind(this));
        this._showContent();
        new OutlineDropdown(this._dropdownControl, this._document);
    }

    _printQuestion(question) {
        let divContainer = this._document.createElement('div');
        let headline = this._document.createElement('h1');
        let span = this._document.createElement('span');
        span.classList.add('arrow');
        divContainer.classList.add('faq-headline');
        headline.addEventListener('click', e => {
            this._toggleDropdown(e);
        });
        let answer = this._document.createElement('span');

        headline.textContent = question.question;
        answer.innerHTML = question.answer;

        divContainer.appendChild(headline);
        divContainer.appendChild(span);

        let div = this._document.createElement('div');
        div.appendChild(divContainer);
        div.appendChild(answer);

        this._contentContainer.appendChild(div);
    }

    _setActive(link) {
        let container = this._document.querySelector('.help-table');
        let categories = container.getElementsByTagName('li');

        for (let i = 0; i < categories.length; i++) {
            categories[i].classList.remove('active');
        }

        link.classList.add('active');
    }

    /**
     * @param {Link} link
     */
    _printLink(link) {
        let span = this._document.createElement('span');
        span.textContent = link.name;

        let li = this._document.createElement('li');
        li.appendChild(span);

        // default first topic to active
        if (this._outlineLists[1].children[0]) {
            this._outlineLists[1].children[0].classList.add('active');
        }

        this._outlineLists.forEach(ol => {
            let cloned = li.cloneNode(true);
            cloned.addEventListener('click', () => {
                this._setActive(cloned);
                this._printQuestions(link);
                this._document.querySelector('body').dispatchEvent(
                    new CustomEvent(FAQ_SECTION_CLICK_EVENT, {
                        detail: {
                            label: link.name
                        }
                    })
                );
            });
            ol.appendChild(cloned);
        });
    }

    _makeActiveQuestion(event) {
        if (event.srcElement.classList.contains('active-question')) {
            event.srcElement.classList.remove('active-mobile-question', 'active-question');
        } else {
            event.srcElement.classList.add('active-mobile-question', 'active-question');
        }
    }

    _showAnswer(event) {
        event.srcElement.closest('div').classList.toggle('show-answer');
    }

    _createMobileHeader(topic, category, container) {
        let header = this._document.createElement('h1');
        header.classList.add('faq-mobile-click');
        header.innerHTML = topic.name;

        let self = this;

        header.addEventListener('click', function(event) {
            self._makeActiveQuestion(event);
            let label = event.srcElement.innerHTML;
            window.ga('send', 'event', 'FAQ Mobile', label + ' Click');
        })

        category.appendChild(header);
        container.appendChild(category);
    }

    _createQuestions(topic, category) {
        let questionContainer = this._document.createElement('div');
        questionContainer.classList.add('question-container');

        topic.questions.forEach(question => {
            let self = this;
            let grouping = this._document.createElement('div');

            let groupingQuestion = this._document.createElement('div');
            groupingQuestion.classList.add('arrow-container');
            let span = this._document.createElement('span');
            span.classList.add('arrow');

            let questionText = this._document.createElement('p');
            questionText.innerHTML = question.question;
            questionText.classList.add('question-mobile');

            questionText.addEventListener('click', function() {
                self._showAnswer(event);
            })

            groupingQuestion.appendChild(questionText);
            groupingQuestion.appendChild(span);

            let answerText = this._document.createElement('p');
            answerText.innerHTML = question.answer;
            answerText.classList.add('answer-mobile');

            grouping.appendChild(groupingQuestion);
            grouping.appendChild(answerText);
            questionContainer.appendChild(grouping);
        })

        category.appendChild(questionContainer);
    }

    _createMobileLayout(categories){
        categories.forEach(topic => {
            let container = this._document.querySelector('.help-table-mobile');
            let category = this._document.createElement('div');
            category.classList.add('faq-topic-mobile');

            this._createMobileHeader(topic, category, container);
            this._createQuestions(topic, category);
        });

    }

    _printQuestions(category) {
        this._contentContainer.innerHTML = '';
        category.questions.forEach(this._printQuestion.bind(this));
    }
}
