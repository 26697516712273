export class Question {
    /**
     * @param {Number} id
     * @param {String} question
     * @param {String} answer
     */
    constructor(id, question, answer) {
        this.id = id;
        this.question = question;
        this.answer = answer;
    }
}
