export class Video {
    constructor(placeholder, watchVideo, video) {
        this.placeholder = placeholder;
        this.watchVideo = watchVideo;
        this.video = video;
        this.addEventListeners();
    }

    startVideo() {
        this.placeholder.classList.add('remove');
        this.video.src += "?autoplay=1&rel=0";

        if (window.innerWidth < 800) {
            this.video.scrollIntoView();
            // offset for the navigation bar
            window.scrollBy(0, -100);
        }
    }

    addEventListeners() {
        this.placeholder.addEventListener('click', this.startVideo.bind(this));

        for (var i = 0; i < this.watchVideo.length; i++) {
            this.watchVideo[i].addEventListener('click', event => {
                this.startVideo(event);
            });
        }
    }

}