export class ActiveMenuItem {
    /**
     * @param {HTMLDivElement} mobileNavContainer
     * @param {HTMLDivElement} desktopNavContainer
     */
    constructor(path, mobileNavContainer, desktopNavContainer) {
        this._path = path;
        this._mobileNavContainer = mobileNavContainer;
        this._desktopNavContainer = desktopNavContainer;

        this._highlightCurrentRoute();
    }

    _highlightCurrentRoute() {
        [this._mobileNavContainer, this._desktopNavContainer].forEach(
            container => {
                let link = container.querySelector(`a[href="${this._path}"]`);
                if (!link) {
                    let file = this._trimPath(this._path);
                    link = container.querySelector(`a[href="${file}"]`);
                }
                if (link) this._makeLinkActive(link);
            }
        );
    }

    /**
     * @param {String} path
     */
    _trimPath(path) {
        if (path[0] === '/') return path.substr(1);
        return path;
    }

    /**
     * @param {HTMLAnchorElement} link
     */
    _makeLinkActive(link) {
        link.parentElement.classList.add('active');
    }
}
