import { Outline } from '../outline';
import { Link } from '../link';

export const OUTLINE_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export class OutlineParser {
    /**
     * @param {Document} document
     */
    constructor(document) {
        this.doc = document;

        /**
         * @type {Object.<String, Boolean>}
         */
        this._usedIds = {};
    }

    /**
     * @param {String} markup
     * @returns {Outline}
     */
    parse(markup) {
        let outline = new Outline();

        let frame = this.doc.createElement('div');
        frame.innerHTML = markup;

        outline.links = [];

        [...frame.querySelectorAll(OUTLINE_TAGS.join(', '))].forEach(elm => {
            let id = this._idFromInnerText(elm);

            let anchor = this.doc.createElement('span');
            anchor.id = id;
            anchor.classList.add('anchor');
            elm.parentElement.insertBefore(anchor, elm);

            outline.links.push(new Link('#' + id, elm.textContent));
        });

        outline.content = frame.innerHTML;

        return outline;
    }

    /**
     * @param {HTMLHeadingElement} elm
     */
    _idFromInnerText(elm, increment = 0) {
        let id = elm.textContent
            .replace(/[^A-Za-z0-9- ]/g, '') // letters, numbers, dashes, and spaces only
            .toLowerCase()
            .replace(/\s/g, '-');

        if (increment) id += '-' + increment;

        if (this._usedIds[id]) return this._idFromInnerText(elm, increment + 1);

        this._usedIds[id] = true;

        return id;
    }
}
