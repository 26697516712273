export class GetTheApp {
    constructor(button, buttonMobile, getStartedButtons, footerLogos, cancel, container, body) {
        this.button = button;
        this.buttonMobile = buttonMobile;
        this.getStartedButtons = getStartedButtons;
        this.footerLogos = footerLogos;
        this.cancel = cancel;
        this.container = container;
        this.body = body;
        this.addEventListeners();
    }

    showModal() {
        this.body.classList.add('fixed-body');
        this.container.classList.add('show-modal');
    }

    closeModal() {
        this.body.classList.remove('fixed-body');
        this.container.classList.remove('show-modal');
    }

    addEventListeners() {
        this.button.addEventListener('click', this.showModal.bind(this));
        this.buttonMobile.addEventListener('click', this.showModal.bind(this));
        this.cancel.addEventListener('click', this.closeModal.bind(this));

        for (var i = 0; i < this.getStartedButtons.length; i++) {
            this.getStartedButtons[i].addEventListener('click', event => {
                this.showModal(event);
            });
        }

        for (var x = 0; x < this.footerLogos.length; x++) {
            this.footerLogos[x].addEventListener('click', event => {
                this.showModal(event);
            });
        }
    }
}
