// eslint-disable-next-line no-unused-vars
import { Link } from './link';

export class Outline {
    /**
     * @param {Link[]} links
     * @param {String} content
     */
    constructor(links = [], content) {
        this.links = links;
        this.content = content;
    }
}
