const isTouchDevice = require('is-touch-device');

export const DROPDOWN_CONTROL_CLASS = 'dropdown-control';
const DROPDOWN_MENU_SELECTOR = '.dropdown-menu';
const FIELD_SELECTOR = 'input';
const INPUT_SELECTOR = '.input';
const INPUT_TEXT_SELECTOR = '.input-text';
const OPEN_CLASS = 'open';

export class Dropdown {
    /**
     * @param {HTMLDivElement} container
     * @param {Document} doc
     */
    constructor(container, doc) {
        this.container = container;
        this.doc = doc;

        /** @type {HTMLInputElement} **/
        this.field = this.container.querySelector(FIELD_SELECTOR);

        /** @type {HTMLDivElement} **/
        this.input = this.container.querySelector(INPUT_SELECTOR);

        /** @type {HTMLSpanElement} */
        this.inputText = this.input.querySelector(INPUT_TEXT_SELECTOR);

        /** @type {HTMLUListElement} **/
        this.menu = this.container.querySelector(DROPDOWN_MENU_SELECTOR);

        this._addEventListeners();
    }

    _addEventListeners() {
        this.container
            .querySelector(INPUT_SELECTOR)
            .addEventListener('click', this._toggleDropdown.bind(this));
        [...this.container.querySelectorAll('li')].forEach(li =>
            li.addEventListener('click', this._handleOptionClick(li).bind(this))
        );
    }

    /**
     * @param {Boolean|null} open
     */
    _toggleDropdown(open = null) {
        // suppress event being passed in
        if (typeof open === 'object') open = null;

        let isOpen = this.container.classList.contains(OPEN_CLASS);
        if (open === true || (open === null && !isOpen)) {
            this.container.classList.add(OPEN_CLASS);

            setTimeout(this._listenForCloseEvents.bind(this), 0);
        } else {
            this.container.classList.remove(OPEN_CLASS);

            this._stopListeningForCloseEvents();

            this._dispatchInputEvent();
        }
    }

    /**
     *
     * @param {HTMLLIElement} li
     */
    _handleOptionClick(option) {
        return () => {
            let selectedElm = this.menu.querySelector('li.selected');
            if (selectedElm) selectedElm.classList.remove('selected');

            option.classList.add('selected');

            this.field.value = option.dataset.value;
            this.inputText.textContent = option.textContent;
            this.input.classList.add('not-empty');

            this._toggleDropdown(false);

            this._dispatchInputEvent();
        };
    }

    _dispatchInputEvent() {
        let event = document.createEvent('Event');
        event.initEvent('blur', false, true);
        this.field.dispatchEvent(event);
    }

    _listenForCloseEvents() {
        this.docClickCallback = this._handleDocumentClick.bind(this);
        this.doc.addEventListener(this._getCloseEvent(), this.docClickCallback);

        this.docKeyDownCallback = this._handleDocKeyDown.bind(this);
        this.doc.addEventListener('keydown', this.docKeyDownCallback);
    }

    _stopListeningForCloseEvents() {
        this.docClickCallback &&
            this.doc.removeEventListener(
                this._getCloseEvent(),
                this.docClickCallback
            );

        this.docKeyDownCallback &&
            this.doc.removeEventListener('keydown', this.docKeyDownCallback);
    }

    _handleDocumentClick(e) {
        if (
            e.target.closest(DROPDOWN_MENU_SELECTOR) == this.menu ||
            e.target.closest('.input') == this.input
        )
            return;

        this._toggleDropdown(false);
    }

    _handleDocKeyDown(e) {
        if (e.keyCode === 27) this._toggleDropdown(false);
    }

    _getCloseEvent() {
        return isTouchDevice() ? 'touchstart' : 'click';
    }
}
