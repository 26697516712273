import { Page } from './page';
import { Form } from '../forms/form';
import { Contact as ContactForm } from '../forms/types/contact';

export class Contact extends Page {
    init() {
        super.init();

        this._form();
    }

    _setPageTitle() {
        this._document.title = 'optOn | Contact';
    }

    _form() {
        let contactForm = document.getElementById('contact-form');

        if (contactForm)
            new ContactForm(new Form(contactForm), document, this._http);
    }
}
