export class ScrollIntoView {
    constructor(element, target) {
        this.element = element;
        this.target = target;
        this.addEventListeners();
    }

    scroll() {
        this.target.scrollIntoView();
        // offset for the navigation bar
        window.scrollBy(0, -100);
    }

    addEventListeners() {
        if (this.element)
            this.element.addEventListener('click', this.scroll.bind(this));
    }
}
