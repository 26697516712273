const AUTO_SLIDE_DURATION = 12000;

export class Carousel {
    constructor(container, arrows) {
        this.arrows = arrows;
        this.container = container;
        this.testimonials = this.container.querySelectorAll('.testimonial');

        this.currentIndex = 0;

        this.addEventListeners();
        this.setTimer();
    }

    setTimer() {
        this.timer = setInterval(this.moveForward.bind(this), AUTO_SLIDE_DURATION);
    }

    stopTimer() {
        clearTimeout(this.timer);
    }

    moveForward() {
        this.currentIndex++;
        if (this.currentIndex > this.testimonials.length - 1)
            this.currentIndex = 0;

        this.clearCurrentlyActive();
        this.testimonials[this.currentIndex].classList.add('active');
    }

    moveBackwards() {
        this.currentIndex--;
        if (this.currentIndex < 0)
            this.currentIndex = this.testimonials.length - 1;

        this.clearCurrentlyActive();
        this.testimonials[this.currentIndex].classList.add('active');
    }

    clearCurrentlyActive() {
        let activeElm = this.container.querySelector('.testimonial.active');
        if (activeElm) activeElm.classList.remove('active');
    }

    addEventListeners() {
        for (var i = 0; i < this.arrows.length; i++) {
            this.arrows[i].addEventListener('click', event => {
                // this.stopTimer();

                if (event.currentTarget.classList.contains('forward')) {
                    this.moveForward();
                } else {
                    this.moveBackwards();
                }
            });
        }
    }
}
