import { FormType } from './form-type';
import { CONTACT_FORM_ERROR_EVENT, CONTACT_FORM_SUCCESS_EVENT } from '../../analytics/google';

export class Contact extends FormType {
    /**
     * @param {Form} form
     * @param {Document} doc
     * @param {Http} http
     */
    constructor(form, doc, http) {
        super(form, doc, http);

        let contactUsContainer = doc.querySelector(
            '.contact-us-form-container'
        );
        if (contactUsContainer) {
            this._successMessage = contactUsContainer.querySelector(
                '.form-success-message'
            );
            this._successContainer = contactUsContainer.querySelector(
                '.form-success-field'
            );
            this._errorMessage = contactUsContainer.querySelector(
                '.form-error-message'
            );
        }
    }

    _recaptchaComplete() {
        this.http.post('/v1/website/contact', this.form.getData()).then(
            () => {
                this._stopLoading();
                this._showSuccess();

                this.doc.querySelector('body').dispatchEvent(
                    new CustomEvent(CONTACT_FORM_SUCCESS_EVENT, {
                        detail: this.form.getData()
                    })
                );

            },
            () => {
                this._stopLoading();

                this.doc.querySelector('body').dispatchEvent(
                    new CustomEvent(CONTACT_FORM_ERROR_EVENT, {
                        detail: this.form.getData()
                    })
                );

                this._showUnknownError();
            }
        );
    }

    _showSuccess() {
        this.form.addClass('hide');
        this._successContainer.classList.remove('hide');
        this._createMessage();
    }

    _createMessage() {
        let formData = this.form.getData();
        let firstName = formData['firstname'];
        let subject = formData['subject'];
        let title = document.createElement('p');
        let message = document.createElement('p');

        title.classList.add('success-title');
        message.classList.add('success-message');

        title.innerHTML = 'Thank you ' + firstName + '!';
        message.innerHTML =
            'Our team has recieved your inquiry about ' +
            subject +
            ' and will respond to you within 24 hours. In the meantime, check out the following:';

        this._successMessage.append(title, message);
        this._successMessage.scrollIntoView();
        window.scrollBy(0, -200);
    }

    _showUnknownError() {
        this.form.addClass('hide');
        // this._errorMessage.classList.remove('hide');
    }
}
