const base = {
    environment: 'base',
    recaptcha: '6LeXsmwUAAAAAPLk993m0Zud8q-VnExCZrgAACoC',

    hubspot: {
        formIds: {
            contact: '87be47c8-7a50-4526-a4e3-218718d64a8d',
            newsletter: '686b2bc1-1854-4e8a-8592-6565ee1cfa88'
        }
    },

    /**
     * @typedef {{baseUrl: string}} driveOnApi
     */
    driveOnApi: {
        baseUrl: 'https://dbec5af5136e0e8434ace4c6f678253b.t72.pkiapps.com/api'
    }
};

const test = Object.assign({}, base, {
    environment: 'test'
});

const uat = Object.assign({}, base, {
    environment: 'uat',
    driveOnApi: {
        baseUrl: 'https://beta.getopton.com/api'
    }
});

const production = Object.assign({}, base, {
    environment: 'production',
    driveOnApi: {
        baseUrl: 'https://www.getopton.com/api'
    },
    hubspot: {
        formIds: {
            contact: 'e8db187f-3d3a-4ef7-85ab-daac09661b17',
            newsletter: '9c83c012-909a-445f-9fc9-932c91db11bd'
        }
    }
});

/**
 * @type {{environment: string, driveOnApi: driveOnApi}}
 */
let config;

switch (DO_ENV) {
    case 'production':
        config = production;
        break;
    case 'uat':
        config = uat;
        break;
    case 'test':
        config = test;
        break;
    default:
        config = base;
        break;
}

export default config;
