import { Page } from './page';
import { VERIFY_EMAIL_ERROR_EVENT, VERIFY_EMAIL_SUCCESS_EVENT } from '../analytics/google';

export class VerifyEmail extends Page {
    init() {
        super.init();

        // hide div containing the noscript message
        this._document.querySelector('.verify-email__verify-default').classList.add('hide');

        // verify token
        this._verifyToken();
    }

    _setPageTitle() {
        this._document.title = 'optOn | Verify Email';
    }

    _verifyToken(){
        // get token
        let token = this._getToken();

        // validate token
        if (token !== null) {
            let data = {
                "token": token
            };

            // validate token
            this._http.post('/v1/user/verify', data).then(
                () => {
                    // show success message
                    this._document.querySelector('.verify-email__verify-success').classList.remove('hide');
                    this._document.querySelector('body').dispatchEvent(
                        new CustomEvent(VERIFY_EMAIL_SUCCESS_EVENT)
                    );
                },
                statusCode => {
                    if (statusCode != 200) {
                        // show failed message
                        this._document.querySelector('.verify-email__verify-fail').classList.remove('hide');
                        this._document.querySelector('body').dispatchEvent(
                            new CustomEvent(VERIFY_EMAIL_ERROR_EVENT)
                        );
                    }
                }
            );
        } else {
            // show failed message
            this._document.querySelector('.verify-email__verify-fail').classList.remove('hide');
            this._document.querySelector('body').dispatchEvent(
                new CustomEvent(VERIFY_EMAIL_ERROR_EVENT)
            );
        }
    }

    _getToken(){
        let
            token = window.location.pathname.split('/').pop(),
            pattern = /^[A-Z0-9]+$/g;

        // if we're getting a string as a token, return it, otherwise, return null so that the API call will fail
        return pattern.test(token) ? token : null;
    }
}
