import 'whatwg-fetch';

export class Http {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    /**
     * @param {String} uri
     * @returns {Promise<Object>}
     */
    get(uri) {
        return this._processResponse(fetch(this.baseUrl + uri));
    }

    /**
     * @param {String} uri
     * @param {Object} data
     * @returns {Promise<Object>}
     */
    post(uri, data) {
        return this._processResponse(
            fetch(this.baseUrl + uri, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
        );
    }

    /**
     * @param {Promise<Object>} promise
     * @returns {Promise<Object>}
     */
    _processResponse(promise) {
        return new Promise((resolve, reject) => {
            promise.then(res => {
                if (res.status === 200) {
                    return resolve(
                        res.json().then(res => (res.data ? res.data : res))
                    );
                } else {
                    return reject(res.status, res.json());
                }
            }, reject);
        });
    }
}
