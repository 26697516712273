/* eslint no-unused-vars: "off" */
import { Form } from './form';

export const HAS_ERROR_CLASS = 'has-error';

export class FormValidation {
    /**
     * @param {Form} form
     */
    constructor(form) {
        this.form = form;

        this.addEventListeners();
    }

    /**
     * @returns {null}
     */
    addEventListeners() {
        this.form.getFields().forEach(field => {
            field.addEventListener('blur', this.fieldBlurred(field));
            field.addEventListener('input', this.fieldInput(field));
        });
    }

    /**
     * @param {HTMLTextAreaElement|HTMLInputElement} field
     * @returns {Function}
     */
    fieldBlurred(field) {
        return () => {
            if (this.isValid(field)) {
                field.parentElement.classList.remove(HAS_ERROR_CLASS);
            } else {
                field.parentElement.classList.add(HAS_ERROR_CLASS);
                this.setSubmitDisabled(true);
            }
        };
    }

    /**
     * @param {HTMLTextAreaElement|HTMLInputElement} field
     * @returns {Function}
     */
    fieldInput(field) {
        return () => {
            if (this.isValid(field)) {
                if (field.parentElement.classList.contains(HAS_ERROR_CLASS)) {
                    field.parentElement.classList.remove(HAS_ERROR_CLASS);
                }

                if (this.form.isValid()) {
                    this.setSubmitDisabled(false);
                }
            }
        };
    }

    /**
     * @param {HTMLTextAreaElement|HTMLInputElement} field
     * @returns {Boolean}
     */
    isValid(field) {
        return field.checkValidity();
    }

    setSubmitDisabled(disabled) {
        const submitBtn = this.form.getSubmitButton();
        if (submitBtn) submitBtn.disabled = disabled;
    }
}
