import { FormType } from './form-type';
import { PASSWORD_RESET_ERROR_EVENT, PASSWORD_RESET_SUCCESS_EVENT } from '../../analytics/google';

export class PasswordReset extends FormType {
    /**
     * @param {Form} form
     * @param {Document} doc
     * @param {Http} http
     */
    constructor(form, doc, http) {
        super(form, doc, http);
        let mainContainer = doc.querySelector('.new-password-container');

        if (mainContainer) {
            this._mainContainer = mainContainer;
            this._successContainer = mainContainer.querySelector('.form-success');
            this._linkExpired = doc.querySelector('.link-expired');
        }

        // set variables
        this._allRequirementsMet= false;
        this._pwdLength         = false;
        this._min1LowerLetter   = false;
        this._min1UpperLetter   = false;
        this._min1SpecialChar   = false;
        this._RequiredFieldIds  = [
            'password',
            'password_verify',
            'min-8-chars',
            'min-1-lower-letter',
            'min-1-upper-letter',
            'min-1-special-char'
        ],

        // set token
        this._setToken();

        // set bindings
        this.form.onSubmit(this._submitForm.bind(this));
        this.form.getFields().forEach(e => {
            switch (e.name) {
                case 'password_verify':
                case 'password':
                    // validate on field blur
                    e.addEventListener('blur', this._passwordRequirements.bind(this));

                    // verify new password requirements
                    e.addEventListener('keyup', this._passwordRequirements.bind(this));

                    // several onfocus events
                    e.addEventListener('focus', this._onFocusEvents.bind(this));
                    break;
            }
        });
    }

    _onSubmit(e) {
        e.preventDefault();
        this._startLoading();
    }

    _showSuccess() {
        this._scrollToTopPage();
        this.form.addClass('hide');
        this._successContainer.classList.remove('hide');
        this._linkExpired.classList.add('hide');
    }

    _showError() {
        this._scrollToTopPage();
        this.form.addClass('hide');
        this._mainContainer.classList.add('hide');
        this.doc.getElementById('link-error-message').innerHTML = 'Please visit the optOn app to resend the reset password email and try again.';
        this._linkExpired.classList.remove('hide');
    }

    _passwordRequirements(e) {
        let
            formData = this.form.getData(),
            password = formData['password'],
            passwordConfirm = formData['password_verify'],
            label = e.target.nextSibling.nextSibling,
            eventType = e.type,
            fieldName = e.target.name,

            // collect bool results of requirements
            checkList = [
                this._passwordLengthCheck(password), // check for password length
                this._lowerCaseLetterCheck(password), // check for at least 1 lower case letter
                this._upperCaseLetterCheck(password), // check for at least 1 upper case letter
                this._specialCharCheck(password) // check for at least 1 special character
            ];

        // check for errors on blur events
        if (eventType == 'blur' && checkList.every(this._allRqmtsMet) === false) {
            this._fieldisFullError(e);
        }

        // check that all tests are true
        if (checkList.every(this._allRqmtsMet)) {
            // set label back to defult state
            label.classList.remove('password-label-error');

            // only add checkmark to the new password
            if (fieldName == 'password') {
                // add green checkmark
                this._setPasswordField(e, true);
            }

            // enable confirm password field
            this.doc.querySelector('.password_verify').removeAttribute('disabled');

            // confirm both password fields
            if ((fieldName == 'password_verify' && password === passwordConfirm) || (fieldName == 'password' && password === passwordConfirm)) {
                // add green checkmark to password_verify
                this._setPasswordField(this.doc.getElementById('password_verify'), true, true);

                //
                this._fieldIsSuccess(this.doc.getElementById('password_verify'), true);

                // enable submit btn
                this._enableSubmitBtn();
            } else if (fieldName == 'password_verify') {
                // only do this for blur events
                if (eventType == 'blur') {
                    this._fieldisFullError(e);
                } else {
                    // passwords do not match
                    this._fieldIsError(e);
                }

                // keep the submit btn disabled
                this._disableSubmitBtn();
            } else {
                // When user changes the new password and confirm password don't match, set field to error
                if (fieldName == 'password' && passwordConfirm != '') {
                    this._fieldisFullError(this.doc.querySelector('#password_verify'), true);
                }

                // keep the submit btn disabled
                this._disableSubmitBtn();
            }
        // check if NOTHING has been met, used for default state
        } else if (checkList.every(this._noneRqmtsMet)) {
            label.classList.remove('password-label-error');
            this._setPasswordField(e, false);
            this._disableSubmitBtn();

            // check for verified password
            if (passwordConfirm != password) {
                // set verify password to error state
                this._fieldisFullError(this.doc.querySelector('#password_verify'), true);
            }
        } else {
            this._fieldIsError(e);
        }
    }

    _fieldIsSuccess(e, useElementTree=false) {
        let
            field = (useElementTree ? e : e.target),
            parentDiv = field.parentNode;

        // remove error states
        parentDiv.classList.remove('has-error');

        // remove disabled class from parent div
        parentDiv.classList.remove('disabled');

        // set label back to defult state
        field.nextSibling.nextSibling.classList.remove('password-label-error');
    }

    _fieldIsDefault(e, useElementTree=false) {
        let
            field = (useElementTree ? e : e.target),
            parentDiv = field.parentNode,
            labelField = field.nextSibling.nextSibling;

        // set input's label to default state
        parentDiv.classList.remove('has-error');
        labelField.classList.remove('password-label-error');
        this._setPasswordField(e, false);
        this._disableSubmitBtn();
    }

    _fieldIsError(e, useElementTree=false) {
        let
            field = (useElementTree ? e : e.target),
            labelField = field.nextSibling.nextSibling;

        // set input's label to red
        labelField.classList.add('password-label-error');
        this._setPasswordField(e, false, useElementTree);
        this._disableSubmitBtn();
    }

    _fieldisFullError(e, useElementTree=false) {
        let
            field = (useElementTree ? e : e.target),
            fieldName = field.name;

        this._setFieldsToFullError(e, useElementTree);

        // set unmet requirenment to red only for new password field
        if (fieldName == 'password') {
            this._RequiredFieldIds.forEach(id => {
                this._checkPasswordRequirements(id);
            });
        }
    }

    _setFieldsToFullError(e, useElementTree=false) {
        let
            field = (useElementTree ? e : e.target),
            parentDiv = field.parentNode;

        // remove disabled class from parent div
        parentDiv.classList.remove('disabled');

        // remove success checkmark
        parentDiv.classList.remove('isSuccess');

        // set parent div to error
        parentDiv.classList.add('has-error');

        // set label back to error state
        field.nextSibling.nextSibling.classList.add('password-label-error');
    }

    _setPasswordField(e, isOk, useElementTree=false) {
        let
            field = (useElementTree ? e : e.target),
            parent = field.parentNode;

        if (isOk) {
            // add green checkmark
            parent.classList.add('isSuccess');
            field.classList.add('success-green');
        } else {
            parent.classList.remove('isSuccess');
            field.classList.remove('success-green');
        }
    }

    _allRqmtsMet(check) {
        // check that ALL requirements have been met
        return check === true;
    }

    _noneRqmtsMet(check) {
        // check that none of the requirements have been met
        return check === false;
    }

    _passwordLengthCheck(password) {
        if (password.length >= 8) {
            this._changeRqmtToSuccess('min-8-chars');
            this._pwdLength = true;
            return this._pwdLength;
        } else {
            this._changeRqmtToDefault('min-8-chars');
            this._pwdLength = false;
            return this._pwdLength;
        }
    }

    _lowerCaseLetterCheck(password) {
        if (password.toUpperCase() != password) {
            this._changeRqmtToSuccess('min-1-lower-letter');
            this._min1LowerLetter = true;
            return this._min1LowerLetter;
        } else {
            this._changeRqmtToDefault('min-1-lower-letter');
            this._min1LowerLetter = false;
            return this._min1LowerLetter;
        }
    }

    _upperCaseLetterCheck(password) {
        if (password.toLowerCase() != password) {
            this._changeRqmtToSuccess('min-1-upper-letter');
            this._min1UpperLetter = true;
            return this._min1UpperLetter;
        } else {
            this._changeRqmtToDefault('min-1-upper-letter');
            this._min1UpperLetter = false;
            return this._min1UpperLetter;
        }
    }

    _specialCharCheck(password) {
        // set regex
        let regex = /(?=.*[!@#$%^&*()\-_=+[\]{}\\|;:'",./<>? ])/g;

        if (regex.test(password)) {
            this._changeRqmtToSuccess('min-1-special-char');
            this._min1SpecialChar = true;
            return this._min1SpecialChar;
        } else {
            this._changeRqmtToDefault('min-1-special-char');
            this._min1SpecialChar = false;
            return this._min1SpecialChar;
        }
    }

    _changeRqmtToDefault(id) {
        this.doc.getElementById(id).classList.add('list-default');
        this.doc.getElementById(id).classList.remove('list-success');
        this.doc.getElementById(id).classList.remove('list-error');
    }

    _changeRqmtToSuccess(id) {
        this.doc.getElementById(id).classList.remove('list-default');
        this.doc.getElementById(id).classList.add('list-success');
        this.doc.getElementById(id).classList.remove('list-error');
    }

    _changeRqmtToError(id) {
        this.doc.getElementById(id).classList.remove('list-default');
        this.doc.getElementById(id).classList.remove('list-success');
        this.doc.getElementById(id).classList.add('list-error');
    }

    _passwordMatch(password, passwordConfirm) {
        if (password === passwordConfirm) {
            this.doc.querySelector('.password_verify').classList.remove('has-error');
            return true;
        } else {
            this.doc.querySelector('.password_verify').parentNode.classList.add('has-error');
            return false;
        }
    }

    _checkPasswordRequirements(id) {
        switch (id) {
            case 'password':
                this._setFieldsToFullError(this.doc.querySelector('#password'), true);
                break;
            case 'password_verify':
                this._setFieldsToFullError(this.doc.querySelector('#password_verify'), true);
                break;
            case 'min-8-chars':
                if (this._pwdLength === false) {
                    this._changeRqmtToError(id);
                }
                break;
            case 'min-1-lower-letter':
                if (this._min1LowerLetter === false) {
                    this._changeRqmtToError(id);
                }
                break;
            case 'min-1-upper-letter':
                if (this._min1UpperLetter === false) {
                    this._changeRqmtToError(id);
                }
                break;
            case 'min-1-special-char':
                if (this._min1SpecialChar === false) {
                    this._changeRqmtToError(id);
                }
                break;
        }
    }

    _enableSubmitBtn() {
        this._allRequirementsMet = true;
        this.doc.getElementById('submit-btn').classList.remove('disabled');
    }

    _disableSubmitBtn() {
        this._allRequirementsMet = false;
        this.doc.getElementById('submit-btn').classList.add('disabled');
    }

    _submitForm() {
        // check that all requirements have been met
        if (this._allRequirementsMet === true) {
            this.http.post('/v1/user/reset_password', this.form.getData()).then(
                () => {
                    this._stopLoading();
                    this._showSuccess();
                    this.doc.querySelector('body').dispatchEvent(
                        new CustomEvent(PASSWORD_RESET_SUCCESS_EVENT)
                    );
                    return;
                },
                statusCode => {
                    if (statusCode != 200) {
                        this._stopLoading();
                        this._showError();
                        this.doc.querySelector('body').dispatchEvent(
                            new CustomEvent(PASSWORD_RESET_ERROR_EVENT, {
                                detail: statusCode
                            })
                        );
                        return;
                    }
                }
            );
        } else {
            this._stopLoading();

            // trigger requirement check
            this._RequiredFieldIds.forEach(id => {
                this._checkPasswordRequirements(id);
            });
        }
    }

    _setToken() {
        let
            token = window.location.pathname.split('/').pop(),
            pattern = /^[A-Z0-9]+$/g;

        // if we're getting a string as a token, return it, otherwise, return null so that the API call will fail
        this.doc.getElementById('token').value = (pattern.test(token) ? token : null);
    }

    _onFocusEvents(e) {
        // scroll down
        this._scrollFieldToTopPage(e);

        // reset fields every time user clicks on them
        this._fieldIsDefault(e);
    }

    _scrollFieldToTopPage(e) {
        // only scroll for mobile devices
        if (screen.width <= 800) {
            let fieldName = e.target.name;
    
            // scroll DOWN to the new password field
            if (fieldName == 'password') {
                e.target.scrollIntoView();
                window.scrollBy(0, -100);
            } else if (fieldName == 'password_verify') {
                // scroll UP
                window.scrollBy(0, 100);
            }
        }
    }

    _scrollToTopPage() {
        this.doc.querySelector('body').scrollIntoView();
    }
}
