import { FormType } from './form-type';
import { NEWSLETTER_FORM_SUCCESS_EVENT } from '../../analytics/google';

export class Newsletter extends FormType {
    /**
     * @param {Form} form
     * @param {Document} doc
     * @param {Http} http
     */
    constructor(form, doc, http) {
        super(form, doc, http);

        let signUpContainer = doc.querySelector('.sign-up');
        if (signUpContainer) {
            this._successMessage = signUpContainer.querySelector(
                '.newsletter-form-success'
            );
            this._errorMessage = signUpContainer.querySelector(
                '.newsletter-form-error'
            );
        }
    }

    _recaptchaComplete() {
        this.http.post('/v1/website/newsletter', this.form.getData()).then(
            () => {
                this._stopLoading();
                this._showSuccess();

                this.doc
                    .querySelector('body')
                    .dispatchEvent(
                        new CustomEvent(NEWSLETTER_FORM_SUCCESS_EVENT)
                    );
            },
            (statusCode, res) => {
                this._stopLoading();

                if (statusCode === 400) {
                    // TODO
                    (() => {})(res);
                } else {
                    this._showUnknownError();
                }
            }
        );
    }

    _showSuccess() {
        this.form.addClass('hide');
        this._successMessage.classList.remove('hide');
    }

    _showUnknownError() {
        this.form.addClass('hide');
        this._errorMessage.classList.remove('hide');
    }
}
