import { Page } from './page';
import { Video } from '../components/video';
import { Carousel } from '../components/carousel';
import { tns } from 'tiny-slider/src/tiny-slider';
import AOS from 'aos';
import { AddActiveClass } from '../components/add-active-class';

export class Home extends Page {
    init() {
        super.init();

        this._carousels();
        this._cards();
        this._fadeIns();
        this._videos();
    }

    _setPageTitle() {
        this._document.title = 'optOn | Home';
    }

    _playVideo(video) {
        video.pause();
        video.currentTime = '0';
        video.play();
    }

    _manageSwip(info) {
        let videoContainer = this._document.querySelector(
            '.get-covered-video-container'
        );

        let videos = videoContainer.children;
        let indicator = this._document.querySelector('.three-dots');

        for (let i = 0, len = videos.length; i < len; i++) {
            videos[i].classList.remove('active-video');
        }

        videos[info.index].classList.add('active-video');
        let video = videos[info.index].querySelector('video');
        this._playVideo(video);

        videoContainer.querySelectorAll('img').forEach(img => {
            img.classList.remove('show-replay')
        });

        video.addEventListener('ended', function() {
            videoContainer.querySelector('.active-video img').classList.add('show-replay');
        });

        videoContainer.querySelector('.active-video img').addEventListener('click', function() {
            video.pause();
            // video.currentTime = '0';
            video.play();
        })

        for (let j = 0, len = info.slideItems.length; j < len; j++) {
            info.slideItems[j].children[0].classList.remove('active-slide');
        }

        for (let i = 0; i < indicator.children.length; i++) {
            indicator.children[i].classList.remove('active');
        }

        info.slideItems[info.index].children[0].classList.add('active-slide');
        indicator.children[info.index].classList.add('active');
    }

    _carousels() {
        let slider = tns({
            container: '.tns-text-mobile',
            controls: false,
            nav: false,
            loop: false,
            gutter: 10,
            edgePadding: 25,
            mouseDrag: true,
        });

        slider.events.on('transitionStart', this._manageSwip.bind(this));

        let testimonialsContainer = this._document.querySelector(
            '.testimonial-container'
        );
        let arrows = this._document.querySelectorAll('.carousel-arrow');

        new Carousel(testimonialsContainer, arrows);
    }

    _fadeIns() {
        AOS.init({
            offset: 400,
            duration: 1100
        });
    }

    _cards() {
        let cards = this._document.getElementsByClassName('card');
        let cells = this._document.getElementsByClassName('see-how-text');
        let videoContainer = this._document.getElementsByClassName(
            'get-covered-video-container'
        );
        new AddActiveClass(cards, 'active-card', null);
        new AddActiveClass(cells, 'active-cell', videoContainer);
    }

    _videos() {
        let placeholder = this._document.querySelector('.home-video');
        let youtubeVideo = this._document.querySelector('.iframe-youtube');
        let watchVideo = this._document.getElementsByClassName('click-to-watch');
        new Video(placeholder, watchVideo, youtubeVideo);
    }
}
