export class Form {
    /**
     * @param {HTMLFormElement} form
     */
    constructor(form) {
        this.form = form;
    }

    /**
     * @param {Function} callback
     * @returns {null}
     */
    onSubmit(callback) {
        this.form.addEventListener('submit', callback);
    }

    /**
     * @returns {HTMLDivElement[]}
     */
    getFormControls() {
        return [...this.form.querySelectorAll('.form-control')];
    }

    /**
     * @param {String}
     * @returns {Array<HTMLInputElement|HTMLTextAreaElement>}
     */
    getFields(selector = 'input, textarea') {
        return [...this.form.querySelectorAll(selector)];
    }

    /**
     * @returns {Object}
     */
    getData() {
        return this.getFields().reduce((data, element) => {
            let name = element.name;
            if (name === 'g-recaptcha-response') name = 'recaptcha';

            data[name] = element.value;
            return data;
        }, {});
    }

    /**
     * @returns {HTMLButtonElement}
     */
    getSubmitButton() {
        return this.form.querySelector('button[type="submit"]');
    }

    /**
     * @returns {Boolean}
     */
    isValid() {
        return this.form.checkValidity();
    }

    /**
     * @returns {String}
     */
    getId() {
        return this.form.id;
    }

    /**
     * @param {String} cls
     */
    addClass(cls) {
        this.form.classList.add(cls);
    }

    /**
     * @param {String} cls
     */
    removeClass(cls) {
        this.form.classList.remove(cls);
    }
}
