import { Page } from './page';
import { Form } from '../forms/form';
import { PasswordReset as PasswordResetForm } from '../forms/types/password-reset';

export class PasswordReset extends Page {
    init() {
        super.init();
        this._form();
        this._cssChanges();
    }

    _setPageTitle() {
        this._document.title = 'optOn | Password Reset';
    }

    _form() {
        let passwordResetForm = document.getElementById('passwordReset-form');
        document.querySelector(".cta-container").setAttribute("style", "display: none;");
        if (PasswordReset)
            new PasswordResetForm(new Form(passwordResetForm), document, this._http);
    }

    _cssChanges() {
        // Changes for this page only
        this._document.querySelector('.navigation').classList.add('no-mobile-fixed-nav');
    }
}
