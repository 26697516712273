export class ActiveFollowUs {
    constructor(button, dropdown) {
        this.button = button;
        this.dropdown = dropdown;
        this.addEventListeners();
    }

    showSocialIcons() {
        this.dropdown.classList.toggle('show-dropdown');
    }

    disableFollowUs() {
        this.dropdown.classList.remove('show-dropdown');
    }

    addEventListeners() {
        let socialLinks = this.dropdown.children;
        this.button.addEventListener('click', this.showSocialIcons.bind(this));

        for (var i = 0; i < socialLinks.length; i++) {
            socialLinks[i].addEventListener('click', event => {
                this.disableFollowUs(event);
            });
        }
    }
}
