import { Page } from './page';
// eslint-disable-next-line no-unused-vars
import { Categories, CATEGORY_NAME_TERMS, CATEGORY_NAME_PRIVACY, CATEGORY_NAME_ESIGN, CATEGORY_NAME_ABOUT } from '../data/categories';
// eslint-disable-next-line no-unused-vars
import { Category } from '../data/category';
// eslint-disable-next-line no-unused-vars
import { Link } from '../data/link';

export class Outline extends Page {
    init() {
        super.init();
        this._categories = new Categories(this._http);

        /** @type {HTMLDivElement} */
        this._outlineContainer = this._document.querySelector(
            '.outline-container'
        );

        this._dropdownControl = this._outlineContainer.querySelector(
            '.dropdown-control'
        );

        /** @type {HTMLDivElement} */
        this._loaderContainer = this._document.querySelector('.loader');

        /** @type {HTMLParagraphElement} */
        this._errorText = this._document.querySelector('.loading-error');

        /** @type {HTMLOListElement[]} */
        this._outlineLists = [
            ...this._outlineContainer.querySelectorAll('.outline')
        ];

        /** @type {HTMLDivElement} */
        this._contentContainer = this._outlineContainer.querySelector(
            '.content'
        );

        this._loadData();
    }

    _loadData() {
        return this._categories
            .loadData()
            .then(this._handleSuccess.bind(this), this._handleError.bind(this));
    }

    _handleError() {
        this._hideLoader();
        this._showError();
    }

    _hideLoader() {
        this._loaderContainer.classList.add('hide');
    }

    _showError() {
        this._errorText.classList.remove('hide');
    }

    _showContent() {
        this._outlineContainer.classList.remove('hide');
    }
}
