import { Terms } from './terms';
import { CATEGORY_NAME_PRIVACY } from '../data/categories';

export class Privacy extends Terms {
    _setPageTitle() {
        this._document.title = 'optOn | Privacy';
    }

    _getCategory() {
        return this._categories.getByName(CATEGORY_NAME_PRIVACY);
    }
}
