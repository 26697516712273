import { Contact } from './pages/contact';
import { Help } from './pages/help';
import { Terms } from './pages/terms';
import { Home } from './pages/home';
import { Page } from './pages/page';
import { Privacy } from './pages/privacy';
import { VerifyEmail } from './pages/verifyemail';
import { PasswordReset } from './pages/password-reset';

/**
 * @type {Object.<String, Function>}
 */
export const ROUTES = {
    '/': Home,
    '/contact.html': Contact,
    '/help.html': Help,
    '/terms.html': Terms,
    '/privacy.html': Privacy,
    '/verify-email/(.*)': VerifyEmail,
    '/reset/(.*)': PasswordReset
};

export class Application {
    /**
     * @param {Window} window
     * @param {Document} document
     * @param {Location} location
     */
    constructor(window, document, location) {
        this._window = window;
        this._document = document;
        this._location = location;
    }

    /**
     * @returns {Page}
     */
    getPage() {
        // look for a perfect match
        if (ROUTES[this._location.pathname]) {
            return new ROUTES[this._location.pathname](
                this._window,
                this._document,
                this._location
            );
        } else {
            const newRoute = this._customRoute();

            // check if we got a route with a wildcard
            if (newRoute) {
                return new newRoute(this._window, this._document, this._location);
            }
        }

        return new Page(this._window, this._document, this._location);
    }

    _customRoute() {
        // for look a match with wildcards
        let
            uri = this._location.pathname,
            newRoute = false,
            RouteList = Object.entries(ROUTES);

        // 
        for (const [, [, value]] of Object.entries(Object.entries(RouteList))) {
            let fileName = value[0].split('/')[1].trim(),
                regex = new RegExp(value[0].split('/').pop()),
                func = value[1];

            // look for matching route and that value passes given regex
            if (fileName.length > 0 && regex.test(value)) {
                // match that new router exists
                if (uri.startsWith('/'+ fileName)) {
                    // set new path name
                    fileName = '/'+ fileName +'/'+ value;

                    // create new ROUTE
                    newRoute = {};
                    return newRoute.fileName = func;
                }
            }
        }

        return newRoute;
    }
}
