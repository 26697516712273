export const hubspotAnalytics = env => {
    if (env === 'production') {
        let script = document.createElement('script');
        script.id = 'hs-script-loader';
        script.async = true;
        script.defer = true;
        script.src = '//js.hs-scripts.com/4345503.js';

        document.body.appendChild(script);
    }
};
