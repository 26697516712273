// eslint-disable-next-line no-unused-vars
import { Categories, CATEGORY_NAME_TERMS } from '../data/categories';
// eslint-disable-next-line no-unused-vars
import { OutlineParser } from '../data/parsers/outline-parser';
// eslint-disable-next-line no-unused-vars
import { Category } from '../data/category';
// eslint-disable-next-line no-unused-vars
import { Link } from '../data/link';
import { Outline } from './outline';
import { JumpToTop } from '../components/jump-to-top';
import { OutlineDropdown } from '../components/outline-dropdown';

export class Terms extends Outline {
    init() {
        super.init();

        this._outlineParser = new OutlineParser(this._document);
        this._jumpToTop();
    }

    _setPageTitle() {
        this._document.title = 'optOn | Terms';
    }

    _handleSuccess() {
        let terms = this._getCategory();

        if (terms) return this._printTerms(terms);

        this._handleError();
    }

    _getCategory() {
        return this._categories.getByName(CATEGORY_NAME_TERMS);
    }

    /**
     * @param {Category} terms
     */
    _printTerms(terms) {
        let outline = this._outlineParser.parse(terms.questions[0].answer);

        outline.links.forEach(this._printLink.bind(this));

        outline.content = this._addContactLink(outline.content);

        this._contentContainer.innerHTML = outline.content;

        this._hideLoader();
        this._showContent();
        new OutlineDropdown(this._dropdownControl, this._document);

        return terms;
    }

    /**
     * @param {Link} link
     */
    _printLink(link) {
        let a = this._document.createElement('a');
        a.href = link.url;
        a.textContent = link.text;
        a.addEventListener('click', this._showCTA.bind(this));

        let li = this._document.createElement('li');
        li.appendChild(a);

        let clone = li.cloneNode(true);

        this._outlineLists.forEach(ol => ol.appendChild(clone));
    }

    _jumpToTop() {
        let sideCta = this._document.querySelector('.jump-to-top');

        if (sideCta) new JumpToTop(window, sideCta);
    }

    _showCTA() {
        let sideCta = this._document.querySelector('.jump-to-top');
        sideCta.classList.add('show');
    }

    /**
     * @param {String} html
     * @returns {String}
     */
    _addContactLink(html) {
        html += `<h2>Contact Us</h2>
            <p>
                If you have any questions about these Terms or otherwise need to contact us for any reason,
                You can reach us at <a href="mailto:support@getopton.com">support@getopton.com</a> or visit or contact page.
            </p>
            <a class="blue-btn" href="/contact.html">Contact</a>
        `;

        return html;
    }
}
