import { hubspotAnalytics } from '../analytics/hubspot';
import { googleAnalytics } from '../analytics/google';
import { Hamburger } from '../hamburger';
import { Http } from '../data/http';
import config from '../config';
import { ActiveFollowUs } from '../active-follow-us';
import { GetTheApp } from '../get-the-app';
import { ActiveMenuItem } from '../components/active-menu-item';
import { Download } from '../forms/types/download';
import { Form } from '../forms/form';
import { Newsletter } from '../forms/types/newsletter';

export class Page {
    /**
     * @param {Window} window
     * @param {Document} document
     * @param {Location} location
     */
    constructor(window, document, location) {
        this._window = window;
        this._document = document;
        this._location = location;
    }

    /**
     * @returns {Http}
     */
    get _http() {
        return new Http(config.driveOnApi.baseUrl);
    }

    init() {
        this._setPageTitle();
        this._analytics();
        this._hamburgerMenu();
        this._followUs();
        this._getTheApp();
        this._activeMenuItem();
        this._downloadForm();
        this._newsletterForm();
        this._deviceDetection();
    }

    _analytics() {
        hubspotAnalytics(DO_ENV);
        googleAnalytics(this._window, this._document, DO_ENV);
    }

    _hamburgerMenu() {
        let hamburgerButton = this._document.querySelector('.hamburger');
        let expandedContainer = this._document.querySelector(
            '.hamburger-dropdown'
        );
        let body = this._document.querySelector('body');
        new Hamburger(hamburgerButton, expandedContainer, body);
    }

    _activeMenuItem() {
        let mobileNavContainer = this._document.querySelector(
            '.hamburger-dropdown'
        );
        let desktopNavContainer = this._document.querySelector('.links');

        new ActiveMenuItem(
            this._location.pathname,
            mobileNavContainer,
            desktopNavContainer
        );
    }

    _followUs() {
        let button = this._document.querySelector('.follow-us-button');
        let dropdown = this._document.querySelector('.follow-us-dropdown');
        new ActiveFollowUs(button, dropdown);
    }

    _getTheApp() {
        let button = this._document.querySelector('.cta-container');
        let buttonMobile = this._document.querySelector('.get-the-app-mobile');
        let getStartedButtons = this._document.getElementsByClassName(
            'get-started-button'
        );
        let footerLogos = this._document.getElementsByClassName('footer-logo');
        let cancel = this._document.querySelector('.cancel-modal');
        let container = this._document.querySelector('.get-the-app');
        let body = this._document.querySelector('body');

        new GetTheApp(
            button,
            buttonMobile,
            getStartedButtons,
            footerLogos,
            cancel,
            container,
            body
        );
    }

    _downloadForm() {
        let downloadForm = this._document.querySelector('#download-form');
        new Download(new Form(downloadForm), this._document, this._http);
    }

    _newsletterForm() {
        let newsletterForm = this._document.querySelector('#newsletter-form');
        new Newsletter(new Form(newsletterForm), this._document, this._http);
    }

    _deviceDetection() {
        let ua = navigator.userAgent.toLowerCase();
        if (/ipad|iphone|ipod/.test(ua) && !window.MSStream)
            this._document.body.classList.add('ios');

        if (ua.indexOf('android') > -1)
            this._document.body.classList.add('android');
    }

    _setPageTitle() {
        this._document.title = 'optOn';
    }
}
