export const NOT_EMPTY_CLASS = 'not-empty';

export class FormLabel {
    /**
     * @param {HTMLDivElement} formControl
     */
    constructor(formControl) {
        this.formControl = formControl;

        this.addEventListeners();
    }

    addEventListeners() {
        let field = this.formControl.querySelector('input, textarea');
        if (field)
            field.addEventListener(
                'input',
                () =>
                    field.value
                        ? field.classList.add(NOT_EMPTY_CLASS)
                        : field.classList.remove(NOT_EMPTY_CLASS)
            );
    }
}
