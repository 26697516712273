require('./scroll-tracker');

export const NEWSLETTER_FORM_SUCCESS_EVENT = 'newsletter-form-success';
export const HELP_FORM_SUCCESS_EVENT = 'help-form-success';
export const HELP_FORM_ERROR_EVENT = 'help-form-error';
export const CONTACT_FORM_SUCCESS_EVENT = 'contact-form-success';
export const CONTACT_FORM_ERROR_EVENT = 'contact-form-error';
export const FAQ_SECTION_CLICK_EVENT = 'faq-section-click';
export const VIDEO_CLICK_EVENT = 'click-to-watch';
export const PASSWORD_RESET_SUCCESS_EVENT = 'form-success';
export const PASSWORD_RESET_ERROR_EVENT = 'link-expired';
export const VERIFY_EMAIL_SUCCESS_EVENT = 'verify-email__verify-success';
export const VERIFY_EMAIL_ERROR_EVENT = 'verify-email__verify-fail';

/**
 * @param {Window} w
 * @param {Document} d
 * @param {String} env
 */
export const googleAnalytics = (w, d, env) => {
    let GAID;
    if (env === 'production') {
        GAID = 'UA-122861503-6';
    } else {
        GAID = 'UA-122861503-7';
    }

    w.ga('create', GAID, 'auto');
    w.ga('send', 'pageview');

    w.ga('require', 'scrollDepthTracker', {});

    customEventTracking(w, d);
};

/**
 * @param {Window} w
 * @param {Document} d
 */
const customEventTracking = (w, d) => {
    // ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
    setTimeout(() => {
        const body = d.querySelector('body');

        // Event #1 and #2
        [...d.querySelectorAll('.store a, .stores a')].forEach(elm => {
            elm.addEventListener('click', e => {
                e.preventDefault();
                let location = elm.dataset.location;

                let sent = false;

                let action = 'iOS App Click';
                if (elm.href.toLowerCase().search('google') !== -1) {
                    action = 'Android App Click';
                }

                w.ga('send', 'event', 'Get App', action, location, {
                    transport: 'beacon',
                    hitCallback: function() {
                        sent = true;
                        w.open(elm.href);
                    }
                });

                setTimeout(() => {
                    if (!sent) w.open(elm.href);
                    sent = true;
                }, 400);
            });
        });

        // Event #3 - Get better coverage, on demand video
        [...d.querySelectorAll('.click-to-watch')].forEach(elm => {
            elm.addEventListener('click', e => {
                e.preventDefault();
                w.ga('send', 'event', 'Video', 'Video Click');
            });
        });

        // Event #3 - Get better coverage, on demand video - placeholder click
        [...d.querySelectorAll('.home-video')].forEach(elm => {
            elm.addEventListener('click', e => {
                e.preventDefault();
                w.ga('send', 'event', 'Video', 'Video Click');
            });
        });

        // Event #4 - Get better coverage, on demand video
        [...d.querySelectorAll('.replay')].forEach(elm => {
            let label = elm.classList[1];
            elm.addEventListener('click', e => {
                e.preventDefault();
                w.ga('send', 'event', 'Video', 'Replay Click', label);
            });
        });

        // Event #5 - Policy Card "Get Started" button
        [...d.querySelectorAll('.get-started-button')].forEach(elm => {
            let label = elm.classList[1];
            elm.addEventListener('click', e => {
                e.preventDefault();
                w.ga('send', 'event', 'Get App', 'Get Started Click', label);
            });
        });

        // Event #6 - this custom event is fired when the form is successfully submitted
        body.addEventListener(NEWSLETTER_FORM_SUCCESS_EVENT, () => {
            w.ga('send', 'event', 'Newsletter', 'Newsletter Sign Up');
        });

        // Event #7
        [...d.querySelectorAll('.social-link')].forEach(elm => {
            elm.addEventListener('click', e => {
                e.preventDefault();

                let sent = false;
                let location = 'footer';

                if (elm.closest('div').classList.contains('follow-us-dropdown')) {
                    location = 'header';
                } else if (elm.closest('div').classList.contains('hamburger-social')) {
                    location = 'hamburger';
                }
                
                w.ga(
                    'send',
                    'event',
                    'Follow Us',
                    'Social Media Click ' + location,
                    elm.dataset.platform,
                    {
                        transport: 'beacon',
                        hitCallback: function() {
                            sent = true;
                            w.open(elm.href);
                        }
                    }
                );

                setTimeout(() => {
                    if (!sent) w.open(elm.href);
                    sent = true;
                }, 400);
            });
        });

        // Event #8 - this custom event is fired when the form is successfully submitted
        body.addEventListener(HELP_FORM_SUCCESS_EVENT, e => {
            w.ga(
                'send',
                'event',
                'Form',
                'Help Form Submitted',
                e.detail.device + ' | ' + e.detail.help_category
            );
        });

        // Event #9 - this custom event is fired when the form is NOT successfully submitted
        body.addEventListener(HELP_FORM_ERROR_EVENT, () => {
            w.ga('send', 'event', 'Form', 'Help Form Error', 'Invalid email');
        });

        // Event #10 - this custom event is fired when the form is successfully submitted
        body.addEventListener(CONTACT_FORM_SUCCESS_EVENT, () => {
            w.ga('send', 'event', 'Form', 'Contact Form Submitted');
        });

        // Event #11 - this custom event is fired when the form is NOT successfully submitted
        body.addEventListener(CONTACT_FORM_ERROR_EVENT, () => {
            w.ga(
                'send',
                'event',
                'Form',
                'Contact Form Error',
                'Unknown error'
            );
        });

        // Event #12
        [...d.querySelectorAll('.media-kit a')].forEach(elm => {
            elm.addEventListener('click', e => {
                e.preventDefault();

                let sent = false;

                w.ga(
                    'send',
                    'event',
                    'Download',
                    'Download Media Resources Click',
                    elm.dataset.type,
                    {
                        transport: 'beacon',
                        hitCallback: function() {
                            sent = true;
                            w.open(elm.href);
                        }
                    }
                );

                setTimeout(() => {
                    if (!sent) w.open(elm.href);
                    sent = true;
                }, 400);
            });
        });

        // Event #13-16 - this custom event is fired a click occurs
        body.addEventListener(FAQ_SECTION_CLICK_EVENT, e => {
            w.ga('send', 'event', 'FAQ', e.detail && e.detail.label + ' Click');
        });

        // Event #17
        [...d.querySelectorAll('a.news-link')].forEach(elm => {
            elm.addEventListener('click', e => {
                e.preventDefault();

                let sent = false;

                w.ga(
                    'send',
                    'event',
                    'News',
                    'News Click',
                    elm.dataset.location,
                    {
                        transport: 'beacon',
                        hitCallback: function() {
                            sent = true;
                            w.location = elm.href;
                        }
                    }
                );

                setTimeout(() => {
                    if (!sent) w.location = elm.href;
                    sent = true;
                }, 400);
            });
        });

        // Event #18 - Fires when a user successfully resets their password
        body.addEventListener(PASSWORD_RESET_SUCCESS_EVENT, () => {
            w.ga('send', 'event', 'Password Reset', 'Password Reset Success');
        });

        // Event #19 - Fires when a user FAILS to resets their password
        body.addEventListener(PASSWORD_RESET_ERROR_EVENT, e => {
            w.ga('send', 'event', 'Password Reset', 'Password Reset Error', e.detail);
        });

        // Event #20 - Fires when a user successfully to verified their email
        body.addEventListener(VERIFY_EMAIL_SUCCESS_EVENT, () => {
            w.ga('send', 'event', 'Verify Email', 'Verify Email Success');
        });

        // Event #21 - Fires when a user FAILS to verify their email
        body.addEventListener(VERIFY_EMAIL_ERROR_EVENT, () => {
            w.ga('send', 'event', 'Verify Email', 'Verify Email Fail');
        });
    }, 0);
};
