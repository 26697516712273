import { Application } from './application';
import './polyfills/closest';
import './polyfills/custom-event';

const app = new Application(window, document, location);
const page = app.getPage();
page.init();

// for hot-reload purposes- include all main html pages
if (process.env.NODE_ENV !== 'production') {
    require('../html/contact.html');
    require('../html/help.html');
    require('../html/index.html');
    require('../html/terms.html');
    require('../html/privacy.html');
    require('../html/verify-email.html');
    require('../html/reset.html');
}
